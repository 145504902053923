[contenteditable] {
  outline: 0;
}

.rex-element-hover {
  outline: 2px dashed rgba(255, 79, 127, 0.5) !important;
}

.rex-element-active {
  outline: 2px solid rgba(255, 79, 127, 0.75);
}

.rex-element-hover .rex-editable {
  cursor: default !important;
}

.rex-editable,
.rex-element-active .rex-editable {
  cursor: text !important;
}

.rex-image-placeholder {
  min-width: 100%;
}

.rex-image-placeholder svg {
  width: 100% !important;
  height: auto;
  fill: rgba(38, 47, 63, 0.5);
}

.rex-social-placeholder svg {
  width: 30px !important;
  height: auto;
  fill: rgba(38, 47, 63, 0.65);
}

[data-rex-type="menu"]:empty,
[data-rex-type="social"]:empty {
  height: 20px;
}

[data-rex-type="link"] a:empty {
  display: inline-block;
  min-width: 1px;
  min-height: 1em;
}

.rex-empty-layer {
  text-align: center !important;
}

.rex-drag-active {
  outline: 3px dashed gold !important;
  outline-offset: 0 !important;
  position: relative;
  max-height: 40px;
  overflow: hidden;
  padding: 0;
}

.rex-drag-active:before {
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  background-color: rgba(255, 215, 0, 0.4);
  position: absolute;
  z-index: 1;
}

.rex-dragging {
  opacity: 0.95;
  background-color: #fff;
  box-shadow: 0 15px 30px rgba(0, 11, 29, 0.3);
}

.rex-dragging [data-re-type] {
  margin: 0;
}

.rex-plus-button,
.rex-plus-button:empty {
  display: block;
  border: 3px solid #0058fb;
  font-size: 13px;
  height: 36px;
  width: 36px;
  border-radius: 999px;
  position: relative;
  cursor: pointer;
  margin: 10px auto;
}

.rex-plus-button:after,
.rex-plus-button:before,
.rex-plus-button:empty:after,
.rex-plus-button:empty:before {
  content: "";
  display: block;
  background-color: #0058fb;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rex-plus-button:before,
.rex-plus-button:empty:before {
  height: 1.5em;
  width: 3px;
}

.rex-plus-button:after,
.rex-plus-button:empty:after {
  height: 3px;
  width: 1.5em;
}

.rex-plus-button:empty:hover,
.rex-plus-button:hover {
  border-color: #0044c3;
}

.rex-plus-button:empty:hover:after,
.rex-plus-button:empty:hover:before,
.rex-plus-button:hover:after,
.rex-plus-button:hover:before {
  background-color: #0044c3;
}

span.variable-pill {
  background-color: #e2ebff;
  border: none;
  pointer-events: none;
  color: #000;
  display: inline;
  padding: 4px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
