/*TODO NEED TO REMOVE THIS IN FEATURE*/
@tailwind base;

:root {
  /* --color-primary: theme(colors.primary.600); */
  --color-light-gray: theme(colors.neutral.300);
  --color-alice-gray: theme(colors.neutral.50);
  --color-white: theme(colors.whiteBlack.100);
  --color-gray: theme(colors.neutral.500);
  --color-lavender: theme(colors.neutral.200);
  --color-blue: theme(colors.primary.1000);
  --color-dark-black: theme(colors.neutral.1000);
  --color-light-black: theme(colors.neutral.800);
  --color-min-black: theme(colors.neutral.900);
  --color-sky-blue: theme(colors.primary.100);
  --color-light-white: theme(colors.neutral.100);
  --color-gray-300: theme(colors.neutral.300);
  --color-black-700: theme(colors.neutral.700);
  --color-gray-500: theme(colors.neutral.600);
  --color-gray-700: theme(colors.neutral.400);
  --color-blue-980: theme(colors.blue.100);
  --color-white-960: theme(colors.whiteBlack.500);
  --color-red-700: theme(colors.red.600);
}

.image-upload-section {
  padding: 16px;
  background: #f7f9ff;
  border: 1px solid #e7e7e8;
  border-radius: 8px;
}
.inventory-modal > div {
  padding: 0;
}
.inventory-modal > div > div {
  height: 100vh;
  border-radius: 0;
}
.inventory-modal > div > div > div:nth-child(1) {
  padding: 14px;
}
.assets-toggle-false .bg-blue-700 {
  background-color: var(--color-light-gray);
  border-color: 1px soild var(--color-light-gray);
}
.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  user-select: none;
}
.checkbox-group > * {
  margin: 0.5rem 0.5rem;
}
.checkbox-group-legend {
  font-size: 1.5rem;
  font-weight: 700;
  color: #9c9c9c;
  text-align: center;
  line-height: 1.125;
  margin-bottom: 1.25rem;
}
.checkbox-input {
  clip: rect(0 0 0 0);
  clippath: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.checkbox-input:checked + .checkbox-tile {
  border-color: var(--color-primary);
  background: aliceblue;
  color: var(--color-primary);
}
.checkbox-input:checked + .checkbox-tile:before {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.checkbox-input:checked + .checkbox-tile .checkbox-label {
  color: var(--color-primary);
}
.checkbox-label {
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
}
.checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: 2px solid transparent;
  background-color: var(--color-lavender);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
  padding: 8px 12px;
  color: #000;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 1px;
}
.inner-image {
  max-height: 100px;
  height: 100px;
}
.inner-image .inner-sub-image {
  max-height: 100px;
  height: 100px;
}
.time-picker-select-time {
  top: 402px !important;
}
.time-picker-select-time .rc-time-picker-panel-select::-webkit-scrollbar,
.time-picker-select-time .rc-time-picker-input::-webkit-scrollbar,
.time-picker-select-time .rc-time-picker-panel-input::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.time-picker-select-time li.rc-time-picker-panel-select-option-selected {
  background: var(--color-sky-blue);
}
.time-picker-select-time::before {
  content: "";
  border-bottom: 14px solid #fff;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  position: absolute;
  top: -10px;
  left: 19px;
}
.time-picker-select-time .rc-time-picker-panel-select {
  max-height: 210px;
  margin: 14px 0;
}
.time-picker-select-time .rc-time-picker-panel-input-wrap {
  display: none;
}
.time-picker-select-time .rc-time-picker-panel-select ul {
  height: 213px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.time-picker-select-input .rc-time-picker-input {
  padding: 10px 12px 10px 40px;
  border-radius: 6px;
  border: 1px solid var(--color-light-gray);
  font-size: 16px;
  line-height: 24px;
  height: auto;
}
.booking-tab-section:first-child > button:first-child {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  border: 1px solid var(--color-light-white);
  padding: 10px 16px;
  height: fit-content;
}
.booking-tab-section:first-child > button:last-child {
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  border: 1px solid var(--color-light-white);
  padding: 10px 16px;
  height: fit-content;
  margin: 0;
}
.link-toolbar {
  height: 20px;
  border-radius: 3px;
}
.link-toolbar span {
  padding: 3px !important;
}

/* START weekday style  */
.checkbox-section .checkbox-input {
  clip: rect(0 0 0 0);
  clippath: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.checkbox-section .checkbox-input:checked + .checkbox-tile {
  border-color: var(--color-primary);
  background: aliceblue;
  color: var(--color-primary);
}
.checkbox-section .checkbox-input:checked + .checkbox-tile:before {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.checkbox-section .checkbox-input:checked + .checkbox-tile .checkbox-label {
  color: var(--color-primary);
}
.checkbox-section .checkbox-label {
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
}
.checkbox-section .checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: 2px solid transparent;
  background-color: var(--color-lavender);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
  padding: 8px 12px;
  color: #000;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 1px;
}
/* END weekday style  */

/*  NG PROGRESS CSS */
#nprogress .bar {
  @apply bg-primary-600;
}
#nprogress .bar .peg {
  @apply bg-primary-600 shadow-sm;
}
#nprogress .spinner .spinner-icon {
  @apply border-t-primary-600 border-l-primary-600;
}

.image-gradiant {
  position: relative;
  display: inline-block;
}

.image-gradiant:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}
