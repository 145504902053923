@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/fonts/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/fonts/SourceSansPro-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/fonts/SourceSansPro-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/fonts/SourceSansPro-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/fonts/SourceSansPro-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/fonts/SourceSansPro-Black.ttf") format("truetype");
  font-weight: 900;
}

@layer base {
  #site {
    font-family:
      Source Sans Pro,
      sans-serif;
  }

  .toggle-bg:after {
    top: 1px !important;
  }
}
